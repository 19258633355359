<template>
  <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 30px;">
    <el-breadcrumb-item :to="{ path: '/BannerManagement' }">广告管理</el-breadcrumb-item>
    <el-breadcrumb-item>报名活动</el-breadcrumb-item>
  </el-breadcrumb>
  <div>
    <!-- 1.筛选条件 -->
    <div class="select-box">
      <div class="search">
        <div style="width: 80px;">会员：</div>
        <el-input v-model="option.keyword" clearable placeholder="请输入手机号/名称/ID" @change="clearFun"></el-input>
      </div>
      <div style="margin-left: 30px;">
        <el-button @click="getTabList" type="primary">查 询</el-button>
      </div>
      <div style="margin-left: 30px;">
        <el-button @click="exportData" type="primary" plain>导 出</el-button>
      </div>
    </div>
    <!-- 2. table -->
    <el-table border stripe :data="data.table" style="width: 100%">
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column prop="memberId" label="会员ID" width="200"></el-table-column>
      <el-table-column prop="memberName" label="会员名称" width="200"></el-table-column>
      <el-table-column prop="memberPhone" label="手机号" width="120"></el-table-column>
      <el-table-column label="沙龙老带新" width="100">
        <template #default="scope">
          <span>{{ scope.row.isNew == 0 ? '老客' : '新客' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报名" width="100">
        <template #default="scope">
          <span>{{ scope.row.signUpStatus == 0 ? '未报名' : '已报名' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报名时间" :formatter="formatCreationTime" width="160"></el-table-column>
      <el-table-column label="签到" width="100">
        <template #default="scope">
          <span>{{ scope.row.signInStatus == 0 ? '未签到' : '已签到' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="签到时间" :formatter="formatSignInDateTime" width="160"></el-table-column>

    </el-table>
    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import { ElMessage } from "element-plus";
import * as XLSX from "xlsx"
import { FullTimeFormat } from "../utils/common";
import { useRoute, useRouter } from "vue-router";
import { getApplicationProjectList, returnListExport } from "../http/bannermanagement";
export default {
  methods: {
    formatCreationTime(row) {
      if (row.signUpDateTime) {
        return FullTimeFormat(row.signUpDateTime);
      } else {
        return '-'
      }
    },
    formatSignInDateTime(row) {
      if (row.signInDateTime) {
        return FullTimeFormat(row.signInDateTime);
      } else {
        return '-'
      }
    }
  },
  name: "ApplicationProject",
  setup() {
    const route = useRoute();
    const router = useRouter();
    let bannerManagementId = route.query.bannerManagementId;
    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
      if (bannerManagementId == null || bannerManagementId.trim().length == 0) {
        router.push({ name: "BannerManagement" });
      }
    });
    // 声明数据table表格的内容
    let data = reactive({
      table: [],
      pages: 0,
      total: 0
    });
    //声明查询条件
    let option = reactive({
      memberId: "",
      keyword: "",
      bannerManagementId: bannerManagementId,
      page: 1,
      pageSize: 10,
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let clearFun = () => {
      let obj = {
        memberId: "",
        keyword: "",
        bannerManagementId: bannerManagementId,
        page: 1,
        pageSize: 10
      }
      getApplicationProjectListFun(option);
    }
    let getApplicationProjectListFun = async (obj) => {
      let arr = await getApplicationProjectList(obj);
      data.table = arr.items;
      data.total = arr.totalCount
    };
    //初始化时，获取初始化数据
    getApplicationProjectListFun({
      Page: 1,
      PageSize: 10,
      bannerManagementId: bannerManagementId,
    });

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getApplicationProjectListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page = 1
      await getApplicationProjectListFun(option);
    };
    
    // 导出表格为excel
    let exportData = () => {
      let data = {
        keyword: option.keyword,
        bannerManagementId: option.bannerManagementId
      }
      returnListExport(data).then((res) => {
        if(!res.eValues) return ElMessage.warning({
          message: '导出数据为空，请重新进行数据筛选',
          duration: 2000
        })
        let reg = /\\/g
        let replaceAfter = res.eValues.replace(reg, "")
        var jsondata = JSON.parse(replaceAfter)
        let time = new Date().getTime()
        const workbook = XLSX.utils.book_new()
        const worksheet = XLSX.utils.json_to_sheet(jsondata)
        XLSX.utils.book_append_sheet(workbook, worksheet, "data")
        XLSX.writeFile(workbook, `报名活动列表_${time}.xlsx`)
      })
    }
    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      indexMethod,
      exportData,
      clearFun
    };
  }
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  .search {
    display: flex;
    align-items: center;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
